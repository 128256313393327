import React, {
  useState,
  //  Fragment,
  useContext,
  useEffect,
  useRef,
} from "react"
import Separator from "../components/Elements/Separator"
import StateContext from "../context/state/StateContext"
import Layout from "../components/Layout/Pages"
// import { useStaticQuery, graphql, Link } from "gatsby"
import { usePagination } from "../utils"
import Pagination from "../components/Pagination"
import PageSubtitle from "../components/PageSubtitle"
// import { ChevronDownIcon } from "@heroicons/react/solid"
import {
  // Dialog,
  // Disclosure,
  // Menu,
  Popover,
  // Tab,
  // Transition,
} from "@headlessui/react"
// import {
//   MenuIcon,
//   QuestionMarkCircleIcon,
//   SearchIcon,
//   ShoppingBagIcon,
//   XIcon,
// } from "@heroicons/react/outline"
import Container from "../components/Container"
import { parse } from "query-string"
import BrandGrid from "../components/Cards/BrandGrid"
import BannerSlider from "../components/Commons/BannerSlider"
// import { classNames } from "../utils"
import FilterSelect from "../components/Elements/FilterSelect"
import { viewOptions, sortOptions } from "../data"
import {
  getBrandsListing,
  getOnSiteMarketingCustomLocation,
} from "../helpers/DrupalHelper"
import DataContext from "../context/Data/DataContext"
import ButtonFilter from "../components/Elements/ButtonFilter"
import { useMediaQuery } from "react-responsive"
import Slideout from "../components/Elements/Slideout"
import { isSSR } from "../helpers/RendererHelper"
// import postscribe from "postscribe"
// import Separator from "../components/Elements/Separator"
import AdRegionBrandListing from "../components/AdRegionBrandListing"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
// import AccessDenied from "../components/AccessDenied"
export default function BrandListing({ data, location, pageContext }) {
  // console.log(pageContext)

  const { userType } = useContext(AuthenticationContext)
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  // const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)
  const [currentTab, setCurrentTab] = useState("All Brands")
  const list = pageContext.list
  const [adArray, setAdArray] = useState([])
  const [hero, setHero] = useState([])

  useEffect(() => {
    if (!userType.brandManager && !userType.brandEditor) {
      getOnSiteMarketingCustomLocation(token, "22").then(res => {
        res.items?.length && setHero(res.items[0]["items"])
      })
      getOnSiteMarketingCustomLocation(token, "15,16,17,18").then(res => {
        // console.log(res)
        // console.log("res")
        setAdArray(res.items)
      })
    }
  }, [])

  // const featured = list.filter(v => v.featured === 1)

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  // const allBrands = data.allBrandsListing.nodes
  const { state } = useContext(StateContext)
  const { token } = state
  // console.log(allBrands)
  // let categories = [
  //   { name: "Featured Brands", list: [] },
  //   { name: "All Brands", list: [] },
  // ]
  const [pageNumber, setPageNumber] = useState(1)
  const [loading, setLoading] = useState(false)
  const [allItems, setAllItems] = useState([])
  const [lastItems, setLastItems] = useState([])
  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [perPage, setPerPage] = useState(12)
  // let items = arr.slice(pageNumber * perPage, (pageNumber + 1) * perPage)
  // const { entityItemsNumber } = useContext(DataContext).data
  // const [rand, setRand] = useState(Math.random(100000, 999999))
  const scrollRef = useRef()

  useEffect(() => {
    let pages = Math.ceil(total / perPage)

    if (pages > 1) setTotalPage(pages)
  }, [perPage])

  useEffect(() => {
    let pages = Math.ceil(total / perPage)

    setTotalPage(pages)
  }, [total])

  useEffect(async () => {
    // if (currentTab === "Featured Brands") {
    //   let body = {
    //     featured: "1",
    //     regions: [],
    //     offset: pageNumber.toString(),
    //     item_per_page: perPage.toString(),
    //     sort: [{ title: "asc" }],
    //   }
    //   let data = await getBrandsListing(state.token, body)
    //   // console.log(data)
    //   setAllItems([...data])
    //   setLastItems(allItems)
    // } else
    setAllItems(lastItems)
  }, [currentTab])
  // useEffect(() => {
  //   let pages = Math.ceil(entityItemsNumber.brands / perPage)

  //   // console.log(pages)
  //   if (pages > 1) setTotalPage(pages)
  // }, [perPage])
  // const items = usePagination(allItems, perPage, pageNumber)
  // console.log(items, totalPage, pageNumber)
  // let resultshowing = perPage * pageNumber
  let body = {
    featured: null,
    regions: [],
    offset: "1",
    item_per_page: perPage.toString(),
    sort: [{ title: "asc" }, { created: "desc" }],
  }
  const onSortingChange = async v => {
    // console.log(v)
    setLoading(true)
    if (v === "desc") {
      let body = {
        featured: null,
        regions: [],
        offset: pageNumber.toString(),
        item_per_page: perPage.toString(),
        sort: [{ title: "desc" }],
      }
      let data = await getBrandsListing(state.token, body)
      // console.log(data)
      setAllItems([...data])
    } else if (v == "asc") {
      let body = {
        featured: null,
        regions: [],
        offset: pageNumber.toString(),
        item_per_page: perPage.toString(),
        sort: [{ title: "asc" }],
      }
      let data = await getBrandsListing(state.token, body)
      // console.log(data)
      setAllItems([...data])
    } else if (v === "recent") {
      //
      let body = {
        featured: null,
        regions: [],
        offset: pageNumber.toString(),

        item_per_page: perPage.toString(),
        sort: [{ created: "desc" }],
      }
      let data = await getBrandsListing(state.token, body)
      // console.log(data)
      setAllItems([...data])
    }
    setLoading(false)
  }
  const onPageChange = async v => {
    setLoading(true)
    setPageNumber(v)

    let newPageData = await getBrandsListing(state.token, {
      ...body,
      offset: ((v - 1) * perPage).toString(),
    })
    setAllItems([...newPageData.items])
    setTotal(newPageData.total)
    setLoading(false)
    setLoading(false)
  }
  useEffect(() => {
    if (location.search) {
      let { p } = parse(location.search)
      if (p) onPageChange(parseInt(p))
    } else {
      onPageChange(1)
    }
  }, [])

  // useEffect(() => {
  //   if (adArray.length && !isSSR) {
  //     adArray.map((item, k) => {
  //       if (item.code_snippet !== null && item.code_snippet !== "") {
  //         const script = document.createElement("script")
  //         postscribe(
  //           `#-o2o__adspco-brandListing-P-${k}-${rand}`,
  //           `${item.code_snippet}`
  //         )
  //       }
  //     })
  //   }
  // }, [adArray])

  // const filterCallback = () => {}

  // if (userType.brandManager || userType.brandEditor) return <AccessDenied />
  return (
    <Layout metaTitle={currentTab}>
      <div className="bg-[#F7F7F7]">
        <div className="pt-[20px]">
          <BannerSlider items={hero} />
        </div>
        <Container maxWidth>
          <PageSubtitle
            title="Brand profiles to see how these companies can support you"
            marginTop={
              userType.brandManager ? "20px" : userType.brandEditor && "60px"
            }
          />
          <div className="flex items-center justify-between">
            {isMobile === true && (
              <ButtonFilter
                callback={() => {
                  setMobileMenuOpen(!mobileMenuOpen)
                }}
                title="Filters"
              />
            )}
            <div className="text-reg-15 text-dark">
              Showing {allItems.length} of {total} results
            </div>
            {!isMobile && (
              <section aria-labelledby="filter-heading" className="">
                <div className="flex items-center justify-between">
                  <Popover.Group className="flex">
                    <FilterSelect
                      options={viewOptions}
                      onChange={async v => {
                        setPerPage(v)
                        setLoading(true)

                        let newPageData = await getBrandsListing(state.token, {
                          ...body,
                          offset: 0,
                          item_per_page: v,
                        })
                        setAllItems(newPageData)
                        console.log(newPageData)
                        setLoading(false)
                      }}
                    />
                    <FilterSelect
                      options={sortOptions}
                      onChange={v => onSortingChange(v)}
                      last
                    />
                  </Popover.Group>
                </div>
              </section>
            )}
          </div>

          <div
            className="grid grid-cols-1 gap-y-10 sm:grid-cols-2 gap-x-6 lg:grid-cols-4 xl:gap-x-8 my-8"
            ref={scrollRef}
          >
            {!isSSR && pageNumber === 1 && (
              <AdRegionBrandListing adArray={adArray} />
            )}

            {allItems &&
              allItems.map((brand, k) => (
                <BrandGrid
                  key={k}
                  {...brand}
                  id={brand.nid ? brand.nid : brand.id}
                  loading={loading}
                />
              ))}
            {!allItems.length > 0 && (
              <p className="mt-1 text-sm italic text-gray-500">No brands</p>
            )}
          </div>

          <Pagination
            page={pageNumber}
            setPage={v => onPageChange(v)}
            totalPages={totalPage}
            scrollRef={scrollRef}
          />
          <Slideout
            title="Filter Brands"
            onClose={setMobileMenuOpen}
            open={mobileMenuOpen}
            setOpen={setMobileMenuOpen}
            // toggleSidebar={toggleSidebar}
            fromPage={false}
          >
            <FilterSelect
              options={viewOptions}
              customTitle={"View Options"}
              sidebar
              onChange={async v => {
                setPerPage(v)
                setLoading(true)

                let newPageData = await getBrandsListing(state.token, {
                  ...body,
                  offset: 0,
                  item_per_page: v,
                })
                setAllItems(newPageData)
                console.log(newPageData)
                setLoading(false)
              }}
              defaultValue={viewOptions[0]}
            />
            <Separator className="my-[30px]" />
            <FilterSelect
              options={sortOptions}
              onChange={v => onSortingChange(v)}
              customTitle={"Sort Options"}
              sidebar
              defaultValue={sortOptions[0]}
            />
          </Slideout>
        </Container>
      </div>
    </Layout>
  )
}

// export const query = graphql`
//   query allBrands {
//     allBrandsListing {
//       nodes {
//         number_of_assets
//         number_of_products
//         regions
//         title
//         internal_featured
//         logo
//         name
//         nid
//         created_at
//         asset_covers {
//           id
//           img
//           title
//           type
//         }
//       }
//     }
//   }
// `
