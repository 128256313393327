/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React, { useEffect, useContext, useState } from "react"
// import { Helmet } from "react-helmet"
// import DataContext from "../../../context/Data/DataContext"
// /FilterBar"
// import HTMLReactParser from "html-react-parser"
import postscribe from "postscribe"
import { isSSR } from "../helpers/RendererHelper"

function AdRegionBrandListing(props) {
  const { adArray } = props

  const [rand, setRand] = useState(Math.random(100000, 999999))

  useEffect(() => {
    if (props.adArray.length && !isSSR) {
      props.adArray.map((item, k) => {
        if (item.code_snippet !== null && item.code_snippet !== "") {
          const script = document.createElement("script")
          postscribe(
            `#-o2o__adspco-brandListing-P-${k}-${rand}`,
            `${item.code_snippet}`
          )
        }
      })
    }
  }, [adArray])

  if (Array.isArray(adArray) && adArray.length) {
    return (
      <>
        {adArray.map((item, k) => {
          return (
            <div className="border border-[#3c4fe0] rounded-[8px] min-h-[350px] w-full">
              <div id={`-o2o__adspco-brandListing-P-${k}-${rand}`}></div>
            </div>
          )
        })}
      </>
    )
  }
  return null
}

export default AdRegionBrandListing
