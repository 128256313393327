export const viewOptions = [
  { label: "View 12", value: 12 },
  { label: "View 24", value: 24 },
  { label: "View 48", value: 48 },
]

export const sortOptions = [
  { label: "Most Recent", value: "recent" },
  { label: "Title (asc)", value: "asc" },
  { label: "Title (desc)", value: "desc" },
]
